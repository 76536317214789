import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { store } from '~/pages/heineken_template/_private/store';
import { boleStock_store } from '../_private/boleStock_store';
import { Select } from '@mantine/core';
import { CrossTypeEnum } from '~/modules/screener/containers/useStockScreenerResource';
import { css } from '@emotion/react';
import { VolumeFilter } from './VolumeFilter';
import { useBoleKd } from '~/pages/bole_stock/function/useBoleKd';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
export const StockScreenerDaily = memo(function StockScreenerDaily() {
    const state = useSnapshot(boleStock_store);
    const latestTradeString = useSnapshot(staticStore).tradedDate.intraday;
    const crossTypeOptions = Object.entries(CrossTypeEnum).map(([value, label]) => ({
        value,
        label,
    }));
    const goldenList = useBoleKd({
        bs: 'b',
        date: latestTradeString.format('YYYY-MM-DD'),
    })?.data?.symbols;
    const deathList = useBoleKd({
        bs: 's',
        date: latestTradeString.format('YYYY-MM-DD'),
    })?.data?.symbols;
    const data = state.crossType === 'golden' ? goldenList : deathList;
    return (<styleds.container>
      <styleds.optionContent>
        <span>選股條件</span>
        <Select css={css `
            .mantine-58jpum {
              background-color: #414141;
              height: 38px;
            }
          `} defaultValue={crossTypeOptions.map(s => s.value)[0]} data={crossTypeOptions} onChange={value => (boleStock_store.crossType = value)}/>
      </styleds.optionContent>
      <styleds.symbolListContent>
        <SimpleQuoteListInteractWithChart data={data ?? ['']} chart={store.charting} handleClick={event => {
            boleStock_store.userLastFocusSymbol = event;
            store.charting.changeInterval('1D');
        }}/>
      </styleds.symbolListContent>
      <VolumeFilter />
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 48px calc(100% - 92px) 42px;
    gap: 2px;
  `,
    optionContent: styled.div `
    display: grid;
    grid-template-columns: calc(20% - 4px) 1fr;
    grid-gap: 4px;
    height: 100%;
    background-color: #222222;
    border-radius: 4px;
    font-size: 14px;
    & > * {
      ${fill_horizontal_all_center};
    }
  `,
    symbolListContent: styled.div `
    ${fill_vertical_cross_center};
  `,
};
