import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { BoleStock_Topbar } from './boleStock_Topbar';
import { BoleStock_SidePane2 } from './boleStock_SidePane2';
import { boleStock_initStrategies } from './boleStock_initStrategies';
import { boloeStock_initStyling } from './boleStock_initStyling';
import { BoleStock_SidePane1 } from './boleStock_SidePane1';
import { HighWeightSymbolList } from '../high-weight-stock/HighWeightSymbolList';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { WatchListSymbolList } from '../user-stock/WatchListSymbolList';
import { boleStock_store } from './boleStock_store';
import { PopularStock } from '../popular-stock/PopularStock';
import { CategoryTrendChart } from '../category-trend-chart/CategoryTrendChart';
import { PopularIndustry } from '../popular-industry/PopularIndustry';
import { InvestmentLoginView } from '~/modules/investment-consultant/loginPage/InvestmentLoginView';
import { UnavailableView } from '../component/UnavailableView';
import useMedia from '~/hooks/useMedia';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
export const boleStock_init = {
    global(templateProps) {
        boleStock_initStrategies();
        boloeStock_initStyling(templateProps);
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = <BoleStock_Topbar mainPage={true}/>;
        templateProps.hooks.add(useSignalrStart2_0);
        templateProps.hooks.add(fr_instrument.installData);
    },
    indexPage(templateProps) {
        boleStock_initStrategies();
        boloeStock_initStyling(templateProps);
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            custom_css_url: '../../bole/chartingTheme.css',
        };
        templateProps.layout.login = (<InvestmentLoginView copyright='(112)金管投顧新字第017號 Copyright © 2023' activationCode={true}/>);
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            interval: 'D',
            symbol: boleStock_store.userLastFocusSymbol,
            enableVolumeIndicator: false,
            disabledHeaderWidget: false,
            disabledLeftToolbar: false,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderCompare: true,
            disabledGoToDate: true,
            disabledHeaderSaveload: true,
            overrides: store.charting.lightOverrides,
        };
        templateProps.layout.Row1 = <BoleStock_Topbar mainPage={true}/>;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.useLayout = function useLayout(layouts) {
            const { isPc, isPad } = useMedia();
            return {
                ...layouts,
                Drawer1: isPc || isPad ? BoleStock_SidePane1 : BoleStock_SidePane2,
                Drawer2: isPc || isPad ? BoleStock_SidePane2 : null,
                Row1: <BoleStock_Topbar mainPage={isPc || isPad ? true : false}/>,
            };
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 248px 1fr 336px;
      place-content: flex-start;

      .Chart {
        width: 100%;
        height: 100%;
      }

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Chart Drawer2'
        'Drawer1 Chart Drawer2';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Chart Chart Chart';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Drawer1';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
        // 新增權限確認功能
        templateProps.hooks.add(fr_me.useInstall);
    },
    ['high-weight-stock/index.page'](templateProps) {
        boleStock_init.global(templateProps);
        templateProps.layout.Charting = undefined;
        boleStock_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        templateProps.layout.Row1 = <BoleStock_Topbar mainPage={false}/>;
        templateProps.layout.Col1 = HighWeightSymbolList;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.useLayout = function useLayout(layouts) {
            const { isPc, isPad } = useMedia();
            return {
                ...layouts,
                Drawer1: BoleStock_SidePane1,
                Row1: isPc || isPad ? <BoleStock_Topbar mainPage={false}/> : null,
                Col1: isPc || isPad ? HighWeightSymbolList : null,
                Col2: isPc || isPad ? null : UnavailableView,
                Charting: null,
            };
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 248px 1fr 1fr;
      place-content: flex-start;

      .Col1 {
        width: 100%;
      }

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Col1 Col1'
        'Drawer1 Col1 Col1';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Col2 Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['user-stock/index.page'](templateProps) {
        boleStock_init.global(templateProps);
        templateProps.layout.Charting = undefined;
        boleStock_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        templateProps.layout.Row1 = <BoleStock_Topbar mainPage={false}/>;
        templateProps.layout.Col1 = WatchListSymbolList;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.useLayout = function useLayout(layouts) {
            const { isPc, isPad } = useMedia();
            return {
                ...layouts,
                Drawer1: BoleStock_SidePane1,
                Row1: isPc || isPad ? <BoleStock_Topbar mainPage={false}/> : null,
                Col1: isPc || isPad ? WatchListSymbolList : null,
                Col2: isPc || isPad ? null : UnavailableView,
                Charting: null,
            };
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 248px 1fr 1fr;
      place-content: flex-start;

      .Col1 {
        width: 100%;
      }

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Col1 Col1'
        'Drawer1 Col1 Col1';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['popular-stock/index.page'](templateProps) {
        boleStock_init.global(templateProps);
        templateProps.layout.Charting = undefined;
        boleStock_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        templateProps.layout.Row1 = <BoleStock_Topbar mainPage={false}/>;
        templateProps.layout.Col1 = PopularStock;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.useLayout = function useLayout(layouts) {
            const { isPc, isPad } = useMedia();
            return {
                ...layouts,
                Drawer1: BoleStock_SidePane1,
                Row1: isPc || isPad ? <BoleStock_Topbar mainPage={false}/> : null,
                Col1: isPc || isPad ? PopularStock : null,
                Col2: isPc || isPad ? null : UnavailableView,
                Charting: null,
            };
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 248px 1fr 1fr;
      place-content: flex-start;

      .Col1 {
        width: 100%;
      }

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Col1 Col1'
        'Drawer1 Col1 Col1';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Col2 Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['popular-industry/index.page'](templateProps) {
        boleStock_init.global(templateProps);
        templateProps.layout.Charting = undefined;
        boleStock_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        templateProps.layout.Row1 = <BoleStock_Topbar mainPage={false}/>;
        templateProps.layout.Col1 = PopularIndustry;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.useLayout = function useLayout(layouts) {
            const { isPc, isPad } = useMedia();
            return {
                ...layouts,
                Drawer1: BoleStock_SidePane1,
                Row1: isPc || isPad ? <BoleStock_Topbar mainPage={false}/> : null,
                Col1: isPc || isPad ? PopularIndustry : null,
                Col2: isPc || isPad ? null : UnavailableView,
                Charting: null,
            };
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 248px 1fr 1fr;
      place-content: flex-start;

      .Col1 {
        width: 100%;
      }

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Col1 Col1'
        'Drawer1 Col1 Col1';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Col2 Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
    ['category-trend-chart/index.page'](templateProps) {
        boleStock_init.global(templateProps);
        templateProps.layout.Charting = undefined;
        boleStock_initStrategies();
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.layout.Row1 = <BoleStock_Topbar mainPage={false}/>;
        templateProps.layout.Col1 = CategoryTrendChart;
        templateProps.layout.Drawer1 = BoleStock_SidePane1;
        templateProps.useLayout = function useLayout(layouts) {
            const { isPc, isPad } = useMedia();
            return {
                ...layouts,
                Drawer1: BoleStock_SidePane1,
                Row1: isPc || isPad ? <BoleStock_Topbar mainPage={false}/> : null,
                Col1: isPc || isPad ? CategoryTrendChart : null,
                Col2: isPc || isPad ? null : UnavailableView,
                Charting: null,
            };
        };
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 1fr 1fr;
      grid-template-columns: 248px 1fr 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Col1 Col1'
        'Drawer1 Col1 Col1';

      ${createIPadCss(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Col1 Col1 Col1';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col2 Col2 Col2'
          'Col2 Col2 Col2';
      `)}
    `;
        templateProps.hooks.add(useSignalrStart2_0);
    },
};
